import React, { Component } from 'react';

export class ListLinhas extends Component {
    static displayName = ListLinhas.name;

    constructor(props) {
        super(props);
        this.state = { linhas: [], loading: true };
    }

    componentDidMount() {
        this.populateLinhasData();
    }

    static renderLinhasTable(linhas) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Data Catastro</th>
                        <th>Data Atualiza��o</th>
                        <th>Contatos</th>
                    </tr>
                </thead>
                <tbody>
                    {linhas.map(linha =>
                        <tr key={linha.id}>
                            <td>{linha.nome}</td>
                            <td>{linha.valor}</td>
                            <td>{linha.dataCadastro}</td>
                            <td>{linha.dataAtualizacao}</td>
                            <td>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th>Telefones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linha.contatos.map(contato =>
                                            <tr key={contato.nome}>
                                                <td>{contato.nome}</td>
                                                <td>{contato.email}</td>
                                                <td></td>
                                            </tr>
                                    )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : ListLinhas.renderLinhasTable(this.state.linhas);

        return (
            <div>
                <h1 id="tabelLabel" >Linhas Existentes</h1>
                <p>Veja aqui todas as linhas que temos hoje.</p>
                {contents}
            </div>
        );
    }

    async populateLinhasData() {
        const postData = { "id": 2 };

        try {
            const response = await fetch('linha', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(postData) // body data type must match "Content-Type" header
            });
            const data = await response.json();
            this.setState({ linhas: data, loading: false });
        } catch (error) {
            alert(error);
        }
    }
}
